#banner{
    height: 767px;
    display: flex;
    align-items: center;
    justify-content: center;

    .content{
        display: flex;
        row-gap: 35px;
        text-align: center;
        flex-direction: column;

        h1{
            font-size: 70px;
            color: #ffffff;

            @media(max-width: 991px){
                font-size: 40px;
            }

        }

        p{
            max-width: 60%;
            margin: 0 auto;
            font-size: 26px;
            color: #ffffff;

            @media(max-width: 991px){
                max-width: 100%;
                font-size: 20px;
            }

        }

        .buttons{
            display: flex;
            column-gap: 20px;
            flex-direction: row;
            justify-content: center;

            .button{
                margin: 0;
            }

            .request_a_quote{
                border: none;
            }

        }

    }

    @media(max-width: 991px){
        height: auto;
        padding: 50px 0 75px 0;
    }

}

#features{
    display: flex;
    padding: 70px 0;

    .feature-grid{
        gap: 25px;
        display: flex;
        z-index: 1000;
        position: relative;
        flex-direction: row;
        margin: -150px 0 0 0;

        .feature{
            width: 100%;
            padding: 40px;
            display: flex;
            row-gap: 15px;
            border-radius: 10px;
            flex-direction: column;
            background-color: #ffffff;

            h3{
                font-size: 30px;
                font-weight: 700;
                text-align: center;

                @media(max-width: 1199px){
                    font-size: 22px;
                }

                @media(max-width: 991px){
                    font-size: 20px;
                }

            }

            hr{
                height: 3px;
                width: 69px;
                border: none;
            }

            p{
                font-size: 18px;
                font-weight: 400;
                color: #000000;
                text-align: center;

                @media(max-width: 1199px){
                    font-size: 16px;
                }

            }

            @media(max-width: 1199px){
                padding: 20px;
            }

            @media(max-width: 991px){
                margin: 0 auto;
                max-width: 500px;
            }

        }

        @media(max-width: 991px){
            flex-direction: column;
        }

        @media(max-width: 767px){
            margin: -75px 0 0 0;
        }

    }

    .content{
        display: flex;
        row-gap: 30px;
        padding: 70px 0 0 0;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        h2{
            font-size: 50px;
            color: #ffffff;
            text-align: center;

            @media(max-width: 991px){
                font-size: 30px;
            }

        }

        p{
            max-width: 60%;
            margin: 0 auto;
            font-size: 26px;
            font-weight: 400;
            text-align: center;

            @media(max-width: 991px){
                font-size: 20px;
                max-width: 80%;
            }

            @media(max-width: 767px){
                max-width: 100%;
            }

        }

        @media(max-width: 991px){
            padding: 40px 0 0 0;
        }

    }
    
    @media(max-width: 991px){
        padding: 40px 0;
    }

}